import { useEffect, useState } from 'react'
import { useAppStore, useDialogStore } from '../../store/store'
import shallow from 'zustand/shallow'
import { ICard } from '../../utils/CardsUtils'
import Utils from '../../utils/Utils'

interface DialogProps {
  handleClose: (params: ICard) => void
}

export const Dialog = ({ handleClose }: DialogProps) => {
  const [showDialog, params] = useDialogStore((state) => [state.showDialog, state.params], shallow)
  const [videoURL, setVideoURL] = useState(
    `https://bulgari-scentsorial.s3.eu-central-1.amazonaws.com/videos/${params?.tokenID ?? params?.index}.mp4`
  )

  useEffect(() => {
    const subscriveClose = useDialogStore.subscribe((state) => {})
    return () => subscriveClose()
  }, [])

  const close = () => {
    useDialogStore.getState().toggleDialog(false)
    useAppStore.getState().setShowHome(true)
  }

  // const mindDescription = 'Movements and variations are designed by the brain waves.'
  // const heartDescription = 'Richness and density of colors are generated by the rhythm heartbeat.'

  const itemSelected = {
    title: 'SCENTSORIAL PROJECT',
    subtitle: 'LE GEMME TYGAR',
    description: [
      'Travel on a magnificent journey, Bringing to life the evocative power of Le Gemme Tygar through an immersive 360° multisensorial experience.',
      'Craft your own interpreted digital collectible, created in real time by your senses, your thoughts and your emotions.',
    ],
    mind: [],
    infos: {
      poa: {
        title: 'SCENTSORIAL PROOF OF ATTENDANCE',
        subtitle: 'Smart Contract',
        description: 'Paris 2023, this collectible represents a unique Proof of Attendance.',
      },
      name: {
        title: 'name',
        subtitle: 'Scentsorial Le Gemme Tygar',
      },
      contractAddress: {
        title: 'CONTRACT ADDRESS',
        subtitle: '0x7097C45f8bdD6d77E30D87Fc43B602D70135BDd8',
      },
      brand: {
        title: 'BRAND',
        subtitle: 'Bulgari',
      },
      tag: {
        title: 'TAG',
        subtitle: '#Collectible #Artwork #Emotions #LeGemmeTygar',
      },
      tokenId: {
        title: 'Token Id',
        subtitle: params?.tokenID ?? '0',
      },
      blockchain: {
        title: 'BLOCKCHAIN',
        subtitle: 'POLYGON',
      },
      tokenStandard: {
        title: 'TOKEN STANDARD',
        subtitle: 'TBG by Consensys',
      },
    },
  }

  const infoItem = (item: any, flex = 1) => (
    <div className="info-item-container" style={{ flex: flex }}>
      <p className="info-item-title">{item.title}</p>
      {item.subtitle && <p className="info-item-subtitle">{item.subtitle}</p>}
      {item.description && <p className="info-item-description">{item.description}</p>}
    </div>
  )

  useEffect(() => {
    //if showdialog is true capture esc key and close dialog
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        close()
      }
    }
    if (showDialog) {
      window.addEventListener('keydown', handleEsc)
    }
    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [showDialog])

  const renderImage = () => (
    <div className="detail-image-container">
      {params && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '-webkit-fill-available',
            height: '-webkit-fill-available',
            flexDirection: 'column',
          }}
        >
          <video
            style={{
              flex: 1,
              maxWidth: Utils.isMobile ? '90%' : '100%',
              maxHeight: '100%',
              borderRadius: 24,
            }}
            loop
            autoPlay
            src={videoURL}
            playsInline
            muted
            onError={() => setVideoURL('https://bulgari-scentsorial.s3.eu-central-1.amazonaws.com/videos/196.mp4')}
          />
          {Utils.isMobile && (
            <div className="up-down-animation">
              <svg width="50px" height="50px" version="1.1" viewBox="0 0 752 752" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="m584.85 286.5-198.9 198.9c-2.8438 2.8398-6.1602 4.2617-9.9492 4.2617s-7.1055-1.4219-9.9453-4.2617l-198.9-198.9c-5.6836-5.6836-5.6836-14.68 0-19.891 5.6836-5.6836 14.68-5.6836 19.891 0l188.96 188.96 188.96-188.96c5.6836-5.6836 14.68-5.6836 19.891 0 5.6875 5.6836 5.6875 14.207 0.003906 19.891z"
                  fill="#fff"
                />
              </svg>
            </div>
          )}
        </div>
      )}
    </div>
  )

  const slashIcon = () => (
    <div className="detail-slash">
      <img src={'./assets/img/slash.png'} alt={`scentsorial-img-slash`} />
    </div>
  )

  const renderInformations = () => (
    <div className="detail-text-container">
      {/**** title ****/}

      <div className="space horizontal">
        {slashIcon()}
        <div className="space">
          <h1>{itemSelected.title}</h1>
          <h2>{itemSelected.subtitle}</h2>
        </div>
      </div>

      {/**** description ****/}

      {itemSelected.description.map((it) => (
        <div className="space">
          <p>{it}</p>
        </div>
      ))}

      {/**** heart - mind ****/}

      {/* <div className="detail-charateristics">
        <div className="detail-charateristics-item">
          <p className="char-title">MIND</p>
          <img alt="mind" src="./assets/img/mind.png" />
          <p className="char-description">{mindDescription}</p>
        </div>

        <div className="detail-charateristics-item">
          <p className="char-title">HEART</p>
          <img alt="heart" src="./assets/img/heart.png" />
          <p className="char-description">{heartDescription}</p>
        </div>
      </div> */}

      {/**** Infos ****/}
      <div className="detail-divider" />
      {infoItem(itemSelected.infos.poa)}
      <div className="detail-divider-light" />

      <div className="detail-charateristics">
        {infoItem(itemSelected.infos.name)}
        {slashIcon()}

        {infoItem(itemSelected.infos.contractAddress, 2)}
      </div>
      <div className="detail-divider-light" />

      <div className="detail-charateristics">
        {infoItem(itemSelected.infos.brand)}
        {slashIcon()}
        {infoItem(itemSelected.infos.tag, 2)}
      </div>

      <div className="detail-divider-light" />

      <div className="detail-charateristics">
        {infoItem(
          itemSelected.infos.tokenId ?? {
            title: 'Token Id',
            subtitle: params?.tokenID ?? 0,
          }
        )}
        {slashIcon()}

        <div className="info-item-container" style={{ flex: 2 }}>
          <div className="detail-charateristics">
            {infoItem(itemSelected.infos.blockchain)}
            {slashIcon()}
            {infoItem(itemSelected.infos.tokenStandard)}
          </div>
        </div>
      </div>
    </div>
  )

  const closeButton = () => (
    <div className="dialog-close-button" onClick={close}>
      <img width={16} height={16} alt="close" src="./assets/img/close.png" />
    </div>
  )

  if (showDialog && Utils.isMobile) {
    return (
      <div onClick={close}>
        <div className="dialog-blurred" onClick={close}></div>
        <div className="dialog">
          {renderImage()}
          {renderInformations()}
        </div>
        {closeButton()}
      </div>
    )
  }

  return showDialog ? (
    <div onClick={close}>
      <div className="dialog-blurred" onClick={close}></div>
      <div className="dialog">
        <section className="detail-container">
          {/*Image*/}

          {renderImage()}
          {renderInformations()}
        </section>
        <div className="dialog-close" onClick={close}>
          <img width={20} height={20} src="./assets/img/close.png" alt="close" />
        </div>
      </div>
    </div>
  ) : null
}
