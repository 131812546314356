import { Key } from 'react'
import { useCardStore } from '../../store/store'
import { ICard } from '../../utils/CardsUtils'
import Card from './Card'

export default function Cards() {
  //@ts-ignore
  const { cards } = useCardStore()

  return (
    <group>
      {cards.map((card: ICard, index: Key | null | undefined) => (
        <Card key={index} position={card.position} card={card} />
      ))}
    </group>
  )
}
