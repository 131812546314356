import { Vector3 } from 'three'

class Utils {
  isLocalhost = window.location.hostname === 'localhost'
  isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  cameraConfig = { position: new Vector3(0, 0, this.isMobile ? 350 : 170) }

  resetCamera?: () => void

  proportionateBounds = (value: number, min1: number, max1: number, min2: number, max2: number) => {
    return ((value - min1) * (max2 - min2)) / (max1 - min1) + min2
  }
}

export default new Utils()
