/* eslint-disable */

import { MapControls, MapControlsProps } from '@react-three/drei'
import { useThree } from '@react-three/fiber'
import gsap from 'gsap'
import { useEffect, useRef } from 'react'
import { Vector3 } from 'three'
import { useAppStore, useControlsStore, useDialogStore } from '../../store/store'
import Utils from '../../utils/Utils'

function getWindowSize() {
  const { innerWidth, innerHeight } = window
  return { innerWidth, innerHeight }
}

export default function Controls({ ...props }) {
  const { camera, size } = useThree()

  const mapControl = useRef<MapControlsProps>()
  let animationBlocked = false
  //@ts-ignore
  // const maxLenght = useCardStore((state) => state.cards.length)

  useEffect(() => {
    const subscribeReset = useControlsStore.subscribe((state) => {
      state.resetMapRequired &&
        //@ts-ignore
        gsap.to(mapControl.current?.target, {
          x: 0,
          z: 0,
          duration: 1,
          //@ts-ignore
          onComplete: () => useControlsStore.getState().resetMap(false),
        })
    })

    return () => subscribeReset()
  }, [])

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const id = Number(urlSearchParams.get('id'))

    if (!!id) {
      //remove id from url
      window.history.replaceState({}, document.title, window.location.pathname)
      useAppStore.getState().setShowHome(false)
      useDialogStore.getState().toggleDialog(true, {
        index: id,
        isProduct: false,
        tokenID: id.toString(),
        position: new Vector3(0, 0, 0),
      })
    }
  })

  const moveTo = (x: number, z: number) => {
    if (animationBlocked) return
    animationBlocked = true
    //@ts-ignore
    gsap.to(mapControl.current?.target, {
      x: x,
      z: z,
      duration: 1,
      //@ts-ignore
      onComplete: () => (animationBlocked = false),
    })
  }

  //@todo - refactoring

  const checkBounds = (e?: THREE.Event) => {
    if (animationBlocked) return
    const maxBounds = { x: 1380, z: 1570 }
    const minBounds = { x: -1580, z: -1470 }
    //@ts-ignore
    const currentVertical = mapControl.current?.target.z
    //@ts-ignore
    const currentHorizontal = mapControl.current?.target.x

    const distance = mapControl.current?.getDistance?.() ?? 170
    /**
     * if distance is 170 so maxbounds is {x: 1380, z: 1570} and minbounds is {x: -1580, z: -1470}
     * if distance is 550 so maxbounds is {x: 940, z: 1290} and minbounds is {x: -1150, z: -1170}
     **/

    //@refactoring - export outside
    const minDistance = Utils.isMobile ? 210 : 170
    const maxDistance = Utils.isMobile ? 750 : 550

    //ASCISSE
    // maxmobile with zoom: 1210
    // maxdesktop with zoom: 800 (PRIMA 930) | -1010 ( prima -1150)

    //ORDINATE
    // maxmobile with zoom: 1210
    // maxdesktop with zoom: 1300 (prima 1190) | -1200 ( prima -1170)

    const _maxBounds = {
      x: {
        min: Utils.isMobile ? 1220 : 800,
        max: Utils.isMobile ? 1450 : 1380, // ratio* 773, //1380,
      },
      z: {
        min: Utils.isMobile ? 1150 : 1300,
        max: Utils.isMobile ? 1580 : 1580, // ratio * 885, //1580,
      },
    }

    const _minBounds = {
      x: {
        min: Utils.isMobile ? -1450 : -1010,
        max: Utils.isMobile ? -1690 : -1580,
      },
      z: {
        min: Utils.isMobile ? -1060 : -1200,
        max: Utils.isMobile ? -1450 : -1470,
      },
    }

    maxBounds.x = Utils.proportionateBounds(distance, minDistance, maxDistance, _maxBounds.x.max, _maxBounds.x.min)
    maxBounds.z = Utils.proportionateBounds(distance, minDistance, maxDistance, _maxBounds.z.max, _maxBounds.z.min)

    minBounds.x = Utils.proportionateBounds(distance, minDistance, maxDistance, _minBounds.x.max, _minBounds.x.min)
    minBounds.z = Utils.proportionateBounds(distance, minDistance, maxDistance, _minBounds.z.max, _minBounds.z.min)

    let newVertical = currentVertical
    let newHorizontal = currentHorizontal

    //@todo - refactoring

    newVertical =
      currentVertical > 0
        ? currentVertical > maxBounds.z
          ? maxBounds.z
          : currentVertical
        : currentVertical < minBounds.z
        ? minBounds.z
        : currentVertical

    newHorizontal =
      currentHorizontal > 0
        ? currentHorizontal > maxBounds.x
          ? maxBounds.x
          : currentHorizontal
        : currentHorizontal < minBounds.x
        ? minBounds.x
        : currentHorizontal

    //@ts-ignore
    if (newVertical !== currentVertical) mapControl.current?.target.setZ(newVertical)
    //@ts-ignore
    if (newHorizontal !== currentHorizontal) mapControl.current?.target.setX(newHorizontal)
  }

  return (
    <MapControls
      // zoomSpeed={0.5}
      panSpeed={Utils.isMobile ? 2 : 1.5}
      zoomSpeed={0.5}
      ref={mapControl as any}
      maxPolarAngle={0}
      minPolarAngle={0}
      minAzimuthAngle={0}
      maxAzimuthAngle={0}
      minDistance={Utils.isMobile ? 210 : 170}
      maxDistance={Utils.isMobile ? 750 : 550}
      makeDefault
      enableRotate={false}
      attach=""
      onChange={checkBounds}
      onEnd={() => useDialogStore.getState().enableShow(true)}
      onStart={() => useDialogStore.getState().enableShow(false)}
    />
  )
}
