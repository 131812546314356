import { MapControlsProps } from '@react-three/drei'
import create from 'zustand'
import { CardUtils, ICard } from '../utils/CardsUtils'

/*********** General ************/
export interface GeneralState {
  loaderVisible: boolean
}
export const useGeneralStore = create<GeneralState>((set) => ({
  loaderVisible: true,
}))

/*********** Controls ************/

export interface ControlsState {
  mapControls: MapControlsProps | undefined
  resetMapRequired: boolean
  resetMap: (value: boolean) => {}
}

export const useControlsStore = create<ControlsState>((set, get) => ({
  mapControls: undefined,
  resetMapRequired: false,
  resetMap: async (value: boolean) => set({ resetMapRequired: value }),
}))

/*********** Card ************/

export const useCardStore = create((set) => ({
  cards: [],
  originalData: [],
  loadingData: true,
  maxX: 0,
  maxY: 0,
  minX: 0,
  minY: 0,
  fetchData: async () => {
    await fetch('https://picsum.photos/v2/list?page=1&limit=15')
      .then((res) => res.json())
      .then(async (data) => {
        const cards = await CardUtils.generateCardsConfigWithImage(data)
        const maxX = Math.max(...cards.map((card) => card.position.x))
        const minX = Math.min(...cards.map((card) => card.position.x))
        const maxY = Math.max(...cards.map((card) => card.position.y))
        const minY = Math.min(...cards.map((card) => card.position.y))
        set({ originalData: data, cards, loadingData: false, maxX, minX, maxY, minY })
      })
  },
}))

/*********** Dialog ************/

export interface DialogState {
  showDialog: boolean
  showEnabled: boolean
  params?: ICard
  enableShow: (enabled: boolean) => Promise<any>
  toggleDialog: (visible: boolean, cardData?: ICard) => any
  reset?: boolean
  setReset: (reset: boolean) => any
}

export const useDialogStore = create<DialogState>((set) => ({
  showDialog: false,
  reset: false,
  showEnabled: true,
  toggleDialog: (visible: boolean, cardData?: ICard) =>
    set((state) => (state.showEnabled ? { showDialog: visible, params: cardData } : state)),
  enableShow: async function (enabled: boolean) {
    await set(() => ({ showEnabled: enabled }))
  },
  setReset: (reset: boolean) => {
    set(() => ({ reset }))
  },
  params: undefined,
}))

/*********** App ************/
export interface AppState {
  showHome: boolean
  setShowHome: (visible: boolean) => any
}

export const useAppStore = create<AppState>((set) => ({
  showHome: true,
  setShowHome: (visible: boolean) => {
    set(() => ({ showHome: visible }))
  },
}))

/*********** Textures ************/

export interface TextureState {
  getRandomTexture: () => any // aka texture
  getTexture: (index: number) => any // aka texture
  loadTextures: () => void
  textures: any[]
}
export const useTextureStore = create<TextureState>((set) => ({
  getRandomTexture: () => {},
  getTexture: (index: number) => {},
  loadTextures: () => {},
  textures: [],
}))
