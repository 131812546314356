/*
  Floor component / background plane
*/

import { useLoader } from '@react-three/fiber'
import { TextureLoader } from 'three'

export default function WelcomeText() {
  const texture = useLoader(TextureLoader, './assets/img/scentsorial-title.webp')
  return (
    <>
      <mesh position={[0, -3, 2]}>
        <planeBufferGeometry attach="geometry" args={[235, 235]} />
        <meshBasicMaterial opacity={1} transparent attach="material" map={texture} />
      </mesh>
    </>
  )
}
