import gsap from 'gsap'
import { useEffect, useState } from 'react'
import { useGeneralStore } from '../../store/store'

export default function LoaderScene({ ...props }) {
  const [el, setEl] = useState<HTMLDivElement | null>(null)

  const { loaderVisible } = useGeneralStore()

  useEffect(() => {
    const closeLoader = () => {
      if (!el) return
      console.log('prima de close closeLoader')
      console.time('closeLoader')

      gsap.to(el, {
        duration: 2.2,
        boxShadow: 'inset 0 0 300px 1000px rgba(0, 0, 0, 0)',
      })
      gsap.to(el, {
        delay: 2,
        autoAlpha: 0,
        duration: 2,
        onComplete: () => {
          console.timeEnd('closeLoader')
          useGeneralStore.setState({ loaderVisible: false })
        },
      })
    }

    // const delay = window.innerWidth < 786 ? 2500 : 2500
    // console.log('delay', delay)
    const closeDelayed = () => setTimeout(closeLoader, 0)
    closeDelayed()
  }, [el])

  return loaderVisible ? <div ref={(it) => setEl(it)} className="loadingScene"></div> : null
}
