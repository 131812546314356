import * as THREE from 'three'
import { BoxGeometry, Shape, ShapeGeometry, Texture, TextureLoader } from 'three'

export interface ICard {
  position: THREE.Vector3
  index: number
  textureUrl?: string
  graphic?: Graphics
  isProduct?: boolean
  tokenID?: string
}

export interface Graphics {
  texture?: Texture
  geometry?: THREE.ShapeGeometry
  material?: THREE.MeshStandardMaterial
}

export const defaultCard = {
  geometry: new BoxGeometry(100, 100, 1),
  material: new THREE.MeshStandardMaterial({ opacity: 0, transparent: true }),
}

export class CardUtils {
  static generateCardsConfig(nCards: number = 30): ICard[] {
    const padding = 150
    const offSet = padding / -2
    const cubes: ICard[] = []
    for (let x = -nCards; x < nCards; x++) {
      for (let y = -nCards; y < nCards; y++) {
        //get random element from data
        const randomIndex = Math.floor(Math.random() * nCards)
        if (x === 0 && y === 0) continue
        if (x === 1 && y === 0) continue

        cubes.push({
          position: new THREE.Vector3(x * padding + offSet, y * padding, 0),
          index: randomIndex,
        })
      }
    }
    return cubes
  }

  static async loadTexture(url: string): Promise<Texture> {
    return new Promise((resolve, reject) => {
      new TextureLoader().load(url, resolve, undefined, reject)
    })
  }

  static getShape = (width: number, height: number, radius: number) => {
    const shape = new Shape()

    shape.moveTo(-(width / 2), -(height / 2) + radius)
    shape.lineTo(-(width / 2), height / 2 - radius)
    shape.quadraticCurveTo(-(width / 2), height / 2, -(width / 2) + radius, height / 2)
    shape.lineTo(width / 2 - radius, height / 2)
    shape.quadraticCurveTo(width / 2, height / 2, width / 2, height / 2 - radius)
    shape.lineTo(width / 2, -(height / 2) + radius)
    shape.quadraticCurveTo(width / 2, -(height / 2), width / 2 - radius, -(height / 2))
    shape.lineTo(-(width / 2) + radius, -(height / 2))
    shape.quadraticCurveTo(-(width / 2), -(height / 2), -(width / 2), -(height / 2) + radius)

    return shape
  }

  static getGeometry = (width: number, height: number, radius: number) => {
    const shape = this.getShape(width, height, radius)
    const geometry = new ShapeGeometry(shape)

    return geometry
  }

  static async generateCardsConfigWithImage(data: []): Promise<ICard[]> {
    const nCards = data.length
    const padding = 110
    const offSet = padding / -2
    const cubes: ICard[] = []
    let index = 0

    for (let x = -nCards; x < nCards; x++) {
      for (let y = -nCards; y < nCards; y++) {
        let isProduct = false
        //get random element from data
        const randomIndex = Math.floor(Math.random() * nCards)
        if (x === 0 && y === 0) continue
        if (x === 1 && y === 0) continue
        //@Todo - to remove
        //random image between 10 and 77
        const randomImage = Math.floor(Math.random() * 70) + 197

        //get random number between 0 and 5
        if (Math.floor(Math.random() * 10) === 2) isProduct = true

        const url = isProduct
          ? `./assets/img/product/${Math.floor(Math.random() * 7)}.webp`
          : `https://bulgari-scentsorial.s3.eu-central-1.amazonaws.com/frames-noui/${randomImage}.jpg`
        // const url =
        //   'https://media.discordapp.net/attachments/1007601744269819924/1083407750568091688/file12.webp?width=827&height=827'
        // // ? `./assets/img/product/${Math.floor(Math.random() * 7)}.jpg`
        // // : `https://bulgari-scentsorial.s3.eu-central-1.amazonaws.com/frames-noui/${randomImage}.jpg`

        // : `./assets/img/demo/${randomImage}.jpg`
        // da disabilitare dati demo e recuperare dati da servizio, come commentato

        //check if url going to 404
        // if (!isProduct && (randomImage >= lastIndexGood || (await fetch(url)).status === 404)) {
        //   url = `https://bulgari-scentsorial.s3.eu-central-1.amazonaws.com/frames-noui/197.jpg`
        //   randomImage = 197
        //   lastIndexGood = randomImage - 1
        // }

        //get random between 1 and 5
        cubes.push({
          position: new THREE.Vector3(x * padding + offSet, y * padding, 0),
          index: randomIndex,
          textureUrl: url,
          isProduct: isProduct,
          tokenID: randomImage.toString(),
        })
        index = index + 1
      }
    }
    return cubes
  }
}
