import { Stats } from '@react-three/drei'
import { Canvas } from '@react-three/fiber'
import { NoToneMapping } from 'three'
import shallow from 'zustand/shallow'
import './App.css'
import { Dialog } from './components/dom/Dialog'
import HomeScene from './components/scenes/HomeScene'
import LoaderScene from './components/scenes/LoaderScene'
import { useAppStore, useControlsStore, useDialogStore } from './store/store'
import Utils from './utils/Utils'

function App() {
  const onDialogClosed = () => {}
  //useAppStore.getState().setAppLoaded(true)
  const [showHome] = useAppStore((state) => [state.showHome], shallow)
  const [showDialog] = useDialogStore((state) => [state.showDialog], shallow)

  return (
    <>
      <LoaderScene />
      <div className="App" style={{ cursor: 'grab' }}>
        {!!showDialog && <Dialog handleClose={onDialogClosed} />}
        <Canvas
          dpr={[3, 9]}
          onError={(e) => console.error(e)}
          camera={Utils.cameraConfig as any}
          gl={{ toneMapping: NoToneMapping }}
        >
          <HomeScene />
        </Canvas>

        {Utils.isLocalhost && <Stats />}
        <div className="header">
          <img width={140} src={'./assets/img/bulgari-logo.png'} alt="logo" />
        </div>
        <div className="footer">
          <div
            style={{ display: !showHome ? 'none' : '' }}
            className="footer-content"
            onClick={() => useControlsStore.getState().resetMap(true)}
          >
            <img style={{ marginRight: 1 }} width={20} height={20} src={'./assets/img/home-icon.png'} alt="home" />
          </div>
        </div>
        <a
          className="footer-terms"
          target="_blank"
          rel="noreferrer"
          href="https://bulgari-scentsorial.s3.eu-central-1.amazonaws.com/terms/Terms+%26+Conditions+of+Use+for+NFT+-+Scentsorial+Project+%5BM2200085701%5D.pdf"
        >
          <p>Terms and Conditions</p>
        </a>
      </div>
    </>
  )
}

export default App
