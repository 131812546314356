import { useThree } from '@react-three/fiber'
import { useEffect } from 'react'
import * as THREE from 'three'
import { useCardStore } from '../../store/store'
import Utils from '../../utils/Utils'
import Controls from '../canvas/Controls'
import Environment from '../canvas/Environment'

export default function HomeScene({ ...props }) {
  //add axes helper
  const axesHelper = new THREE.AxesHelper(5000)
  const directionalLightHelper = new THREE.DirectionalLightHelper(new THREE.DirectionalLight(), 5)

  // @ts-ignore
  const { fetchData } = useCardStore()

  useEffect(() => {
    // richiama il servizio per il recupero dei dati, scarica le info in usecardstore.cards e notifica il cambiamento
    // di dati per il rendering nella scena. Cards richiama usecardstore.cards per fillare lo schermo,
    // qui viene gestita la visualizzazione tramite usecardstore.loadingdata
    fetchData()
  }, [fetchData])

  //add
  useThree(({ scene }) => {
    //if domain is localhost add axes
    if (Utils.isLocalhost) {
      scene.add(axesHelper)
      scene.add(directionalLightHelper)
    }
  })

  return (
    <>
      <Environment />
      {/* <OrbitControls /> */}
      <Controls />
      {/* {Constants.test && <OrbitControls />} */}
    </>
  )
}
