/* eslint-disable */
/*
  Card component / Single card component
*/

import { useLoader } from '@react-three/fiber'
import gsap from 'gsap'
import { Suspense, useRef } from 'react'
import { BufferGeometry, FrontSide, Material, Mesh, MeshBasicMaterial, sRGBEncoding, Texture } from 'three'
import { TextureLoader } from 'three/src/loaders/TextureLoader'
import { useAppStore, useDialogStore } from '../../store/store'
import { CardUtils, ICard } from '../../utils/CardsUtils'

export default function Card({ ...props }: { position: THREE.Vector3; card?: ICard }) {
  const { position, card } = props
  const mesh = useRef<Mesh<BufferGeometry, Material | Material[]> | null | undefined>()
  // const mesh2 = useRef<Mesh<BufferGeometry, Material | Material[]> | null | undefined>()
  const texture = useLoader(TextureLoader, card?.textureUrl ?? '') as Texture | undefined

  if (!!texture) {
    texture.repeat.set(0.01, 0.01)
    texture.offset.set(0.5, 0.5)
    texture.encoding = sRGBEncoding
  }
  // set texture to fill the whole card and center it

  /*** Animations ***/
  const animationConfig = {
    duration: 0.5,
    offset: 5,
    ease: 'none',
  }

  const onOver = () => {
    if (card?.isProduct) return
    if (!mesh?.current) return
    gsap.to(mesh.current.position, {
      duration: animationConfig.duration,
      ease: animationConfig.ease,
      y: mesh.current.position.y + animationConfig.offset,
    })
  }

  const onOut = () => {
    if (card?.isProduct) return
    if (!mesh?.current) return
    gsap.to(mesh.current.position, {
      duration: animationConfig.duration,
      ease: animationConfig.ease,
      y: position.y,
    })
  }

  const onCardClicked = () => {
    useDialogStore.getState().toggleDialog(true, card)
    useAppStore.getState().setShowHome(false)
  }

  return (
    <group onClick={!!card?.isProduct ? undefined : onCardClicked}>
      <mesh
        ref={mesh as any}
        position={position}
        onPointerOver={onOver}
        onPointerOut={onOut}
        geometry={CardUtils.getGeometry(100, 100, 10)}
        material={
          new MeshBasicMaterial({
            map: texture,
            side: FrontSide,
          })
        }
      />
    </group>
  )
}
