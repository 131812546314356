/* eslint-disable */

import { Euler } from 'three'
import Cards from './Cards'
import WelcomeText from './WelcomeText'
// import WelcomeText from './WelcomeText'

export default function Environment({ ...props }) {
  return (
    <>
      {/* <Lights /> */}
      <group {...props} rotation={new Euler(Math.PI / -2, 0, 0)}>
        <Cards />
        <WelcomeText />
        {/* <Floor /> */}
      </group>
    </>
  )
}
